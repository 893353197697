import { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import Loading from 'react-fullscreen-loading';

import Navbar from '../Navbar/Navbar'

const Categories = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [showDelModal, setShowDelModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [newName, setNewName] = useState('')
  const [newButtonTitle, setNewButtonTitle] = useState('')
  const [newButtonLink, setNewButtonLink] = useState('')
  const [currentIdCategory, setCurrentIdCategory] = useState('')
  const [newCategory, setNewCategory] = useState('')
  const [buttonTitle, setButtonTitle] = useState('')
  const [buttonLink, setButtonLink] = useState('')
  const [errorInvalidField, setErrorInvalidField] = useState()

  useEffect(() => {
    getAllCategories()
  }, [])

  const getAllCategories = () => {
    fetch('https://inova.elitefotodesign.com.br/api/categories', { method: 'GET' })
    .then(res => res.json())
    .then(res => {
      setCategories(res)
      setPageIsLoading(false)
    })
  }

  const addNewCategory = () => {
    if(newCategory == '') {
      setErrorInvalidField(true)
      return false;
    }

    fetch('https://inova.elitefotodesign.com.br/api/categories', { 
      method: 'POST',
      body: JSON.stringify({
        desc_category: newCategory,
        button_title: buttonTitle,
        button_link: buttonLink,
        token: localStorage.getItem('token')
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res.status && res.status == 'error') {
        alert(res.msg)
      } else {
        setCategories([...categories, res])
        setNewCategory('')
        setButtonLink('')
        setButtonTitle('')
        setErrorInvalidField(false)
      }
    })
  }

  const deleteCategory = () => {
    fetch(`https://inova.elitefotodesign.com.br/api/categories/${currentIdCategory}`, { 
      method: 'DELETE',
      body: JSON.stringify({ token: localStorage.getItem('token') })
    })
    .then(res => res.json())
    .then(res => {
      if(res.status && res.status == 'error') {
        alert(res.msg)
      } else {
        getAllCategories()
      }
    })

    setShowDelModal(false)
  }

  const updateCategory = () => {
    if(!newName) return false

    fetch(`https://inova.elitefotodesign.com.br/api/categories/${currentIdCategory}`, { 
      method: 'PUT',
      body: JSON.stringify({
        desc_category: newName,
        button_title: newButtonTitle,
        button_link: newButtonLink,
        token: localStorage.getItem('token')
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res.status && res.status == 'error') {
        alert(res.msg)
      } else {
        getAllCategories()
      }
    })

    setNewName('')
    setNewButtonLink('')
    setNewButtonTitle('')
    setShowEditModal(false)
  }

  if(pageIsLoading) {
    return <Loading loading background="#ffff" loaderColor="#db1a1a" />
  }

  return (
    <>
      <Navbar/>
      <div className="container">
        <h1 className="my-5 text-left">Gerar categorias para Questionários</h1>
          {
            errorInvalidField &&
            <p className="text-danger text-left">* Preencha todos os campos</p>
          }
        <div className="d-flex flex-wrap mb-5">
          <input onChange={e => setNewCategory(e.target.value)} value={newCategory} type="text" className="form-control col-12 col-md-3 mb-2 mr-2" placeholder="Categoria..." />
          <input onChange={e => setButtonTitle(e.target.value)} value={buttonTitle} type="text" className="form-control col-12 col-md-4 mb-2 mr-2" placeholder="Título do botão..." />
          <input onChange={e => setButtonLink(e.target.value)} value={buttonLink} type="text" className="form-control col-12 col-md-4 mb-2 mr-2" placeholder="Link do botão..." />
          <button onClick={addNewCategory} className="btn btn-success col-md-2 col-12 mb-2">Adicionar categoria</button>
        </div>

        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Categoria</th>
              <th>Título botão</th>
              <th>Link botão</th>
              <th scope="col">Ação</th>
            </tr>
          </thead>
          <tbody>
            {
              categories.map((el, index) => (
                <tr>
                  <td>{el.desc_category}</td>
                  <td>{el.button_title ? el.button_title : '-'}</td>
                  <td>
                    {el.button_link ? <a target="_blank" href={el.button_link}>link</a> : '-'}
                  </td>
                  <td>
                    <button onClick={() => {
                      setCurrentIdCategory(el.id)
                      setNewName(el.desc_category)
                      setNewButtonTitle(el.button_title)
                      setNewButtonLink(el.button_link)
                      setShowEditModal(true)
                    }} className="btn btn-warning mr-1">editar</button>
                    <button onClick={() => {
                      setCurrentIdCategory(el.id)
                      setShowDelModal(true)
                    }} className="btn btn-danger" data-toggle="modal" data-target="#delKeyModal">excluir</button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>

        {/* modal exclusão */}
        <Modal show={showDelModal} onHide={() => setShowDelModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Excluir categoria</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Tem certeza que deseja excluir essa categoria?</p>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={() => setShowDelModal(false)} variant="secondary">Cancelar</Button>
            <Button onClick={deleteCategory} variant="danger">Excluir</Button>
          </Modal.Footer>
        </Modal>
        
        {/* modal edicao */}
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar categoria</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {
            newName == '' &&
            <p className="text-danger">* Preencha o campo abaixo</p>
          }
          <Form.Control className="mb-1" maxlength="50" value={newName} onChange={e => setNewName(e.target.value)} type="text" placeholder="Nova descrição..." />
          <Form.Control className="mb-1" maxlength="100" value={newButtonTitle} onChange={e => setNewButtonTitle(e.target.value)} type="text" placeholder="Título do botão..." />
          <Form.Control maxlength="255" value={newButtonLink} onChange={e => setNewButtonLink(e.target.value)} type="text" placeholder="Link do botão..." />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => setShowEditModal(false)} variant="secondary">Cancelar</Button>
          <Button onClick={updateCategory} variant="primary">Alterar</Button>
        </Modal.Footer>
      </Modal>
      </div>
    </>
  )
}

export default Categories