import ProgressBar from 'react-bootstrap/ProgressBar'
import Card from 'react-bootstrap/Card'
import { useState, useEffect } from 'react'

const ResultQuest = ({ quizId, userId, quizInfo, questions, userQuests }) => {
  const [userOptionsId, setUserOptionsId] = useState([])
  
  useEffect(() => {
    window.scrollTo(0, 0)
    let option;
    let options = [];
    userQuests.forEach(userQuestion => {
      questions.forEach(question => {
        if(question.id == userQuestion.questionId) {
          option = question.options.filter(el => el.optionId == userQuestion.optionId)
          options.push(option[0].optionId)
        }
      })
    })
    setUserOptionsId(options)
  }, [])
  
  const CategType = () => {
    const [allCategs, setAllCategs] = useState([])
    const [allCategoriesId, setAllCategoriesId] = useState([])
    const [porcents, setPorcents] = useState([])

    useEffect(() => {
      let allCategs = []
      questions.map(q => {
        q.options.map(o => {
          if(!allCategs.includes(o.optionCateg))
            allCategs.push(o.optionCateg)
        })
      })
      setAllCategoriesId(allCategs)

      fetch('https://inova.elitefotodesign.com.br/api/categories', { method: 'GET' })
      .then(res => res.json())
      .then(res => {
        setAllCategs(res)
        let arr = []
        let pond = 0
        let totalPond = 0
        res.forEach(el => {
          questions.map(q => {
            q.options.map(opt => {
              if(userOptionsId.includes(opt.optionId) &&
                 opt.optionCateg == el.id) {
                  pond += opt.optionPonderosity
                  totalPond += opt.optionPonderosity
              }
            })
          })


          if(allCategs.includes(parseInt(el.id))) {
            arr.push({
              categId: el.id,
              categTitle: el.desc_category,
              ponderosity: pond,
              porcent: 0
            })
            pond = 0
          }
        })
        
        arr.forEach(el => {
          el.porcent = ((el.ponderosity / totalPond) * 100).toFixed(2)
        })
        sendEmail(arr)
        setPorcents(arr)
      })
    }, [])

    const sendEmail = (arrPorcents) => {
      if(isNaN(arrPorcents[0].porcent)) return;

      fetch('https://inova.elitefotodesign.com.br/api/sendemail', {
        method: 'POST',
        body: JSON.stringify({
          type: 'categ',
          arrPorcents: arrPorcents,
          quizId: quizId,
          userId: userId
        })
      })
    }

    return (
      <>
        {/* {JSON.stringify(porcents)} */}
        <Card className="mt-5 p-3">
          <Card.Body>
              <h1 className="text-danger font-weight-bold">{quizInfo.title}</h1>
              {
              questions.map((el, index) => (
                <div className="mt-5">
                  <p className="font-weight-bold h5">{index+1} - {el.desc}</p>
                  {
                    el.options.map(opt => (
                      <><span className={userOptionsId.includes(opt.optionId) ? "text-primary" : ""}>{opt.optionTitle}</span><br/></>
                    ))
                  }
                </div>
              )) 
              }
          </Card.Body>
        </Card>
        
        <h4 className="mt-5 mb-4">Seu resultado:</h4>
        <div className="row">
          {
            porcents.map(el => {

              return (
                <>
                <div className="col-12 col-md-4 mt-2">
                  <span className="text-primary font-weight-bold">{el.categTitle.toUpperCase()}</span>
                  <ProgressBar variant="primary" style={{height: 20, fontSize: 15, fontWeight: 'bold'}} now={el.porcent} label={`${el.porcent}%`}/>
                </div>
                </>
              )
            })
          }
        </div>
  
        <div className="my-5 text-center d-flex flex-column justify-content-center">
            <p style={{fontSize: 25}}>Parabéns! Agora você já conhece o seu estilo de aprendizagem!</p>
              <p className="font-italic" style={{fontSize: 15}}>
                Todos nós temos os três estilos: visual, auditivo e cinestésico e as nossas 
                preferências podem variar ao longo da nossa vida, conforme vamos nos desenvolvendo e amadurecendo.
                Esse teste tem a intenção de ampliar a sua visão sobre as formas de aprender.
                Os resultados foram enviados para o seu e-mail cadastrado, caso não receba, confira na sua caixa de spam. 
                <br /><br />
                Clique e confira como aproveitar melhor os conteúdos:
              </p>
        </div>
        
        <div className="col-12 mb-5 text-center">
          {
            allCategs.map(el => {
              if(el.button_title)
                return <><a href={el.button_link} className="btn btn-danger m-1 col-6">{el.button_title}</a></>
            })
          }
        </div>
      </>
    )
  }

  const TestType = () => {
    const [correctOptions, setCorrectOptions] = useState(0)

    useEffect(() => {
      questions.map((el, index) => {
        el.options.map(opt => {
          if(userOptionsId.includes(opt.optionId)) {
            setCorrectOptions(correctOptions+1)
          }
        })
      })
    }, [])

    return (
      <>
        <Card className="mt-5 p-3">
          <Card.Body>
              <h1 className="text-danger font-weight-bold">{quizInfo.title}</h1>
              {
              questions.map((el, index) => (
                <div className="mt-5">
                  <p className="font-weight-bold h5">{index+1} - {el.desc}</p>
                  {
                    el.options.map(opt => {
                      if(opt.optionCorrect) {
                        return (
                          <>
                            <span className="text-success">
                              {userOptionsId.includes(opt.optionId) ? <span className="font-weight-bold">{opt.optionTitle} --</span> : opt.optionTitle}
                            </span><br/>
                          </>
                        )
                      } else {
                        return (
                          <>
                            <span className="text-danger">
                              {userOptionsId.includes(opt.optionId) ? <span className="font-weight-bold">{opt.optionTitle} --</span> : opt.optionTitle}
                            </span><br/>
                          </>
                        )
                      }
                    })
                  }
                </div>
              )) 
              }
          </Card.Body>
        </Card>
        <div className="d-flex flex-column align-items-center mt-5 h1">
          {correctOptions}/{questions.length}
          <div className="col-6 col-md-3 mt-3">
            <ProgressBar>
              <ProgressBar animated striped variant="success" now={(correctOptions/questions.length)*100} />
              <ProgressBar animated striped variant="danger" now={100-((correctOptions/questions.length)*100)} />
            </ProgressBar>
          </div>
        </div>
        <div className="my-5 text-center">
          <p style={{fontSize: 25}}>Obrigado por participar!</p>
          <p className="font-italic" style={{fontSize: 15}}>Lembrando que o resultado também foi enviado para seu email (esse envio pode demorar alguns minutos). Também não se esqueça de conferir a caixa de spam.</p>
        </div>
      </>
    )
  }

  return (
    <div className="container text-left">
      {/* {JSON.stringify(userQuests)} <br/><br/>
      {JSON.stringify(questions)} <br/><br/>
      {JSON.stringify(quizInfo)} <br/><br/>
      {JSON.stringify(userId)} <br/><br/>
      {JSON.stringify(quizId)} <br/><br/>

      {JSON.stringify(userOptionsId)} */}
      {
        quizInfo.type == 'category' &&
        <CategType />
      }
      {
        quizInfo.type == 'test' &&
        <TestType />
      }
    </div>
  )
}

export default ResultQuest