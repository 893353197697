import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import Loading from 'react-fullscreen-loading';
import Modal from 'react-bootstrap/Modal'

import Navbar from '../Navbar/Navbar'

const ViewQuests = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true)
  const [quizzes, setQuizzes] = useState([])
  const [currentIdQuiz, setCurrentIdQuiz] = useState('')
  const [currentQuiz, setCurrentQuiz] = useState({})
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getAllQuizzes()
  }, [])

  const getAllQuizzes = () => {
    fetch('https://inova.elitefotodesign.com.br/api/quiz', {
      method: 'GET'
    })
      .then(res => res.json())
      .then(res => {
        setQuizzes(res)
        setPageIsLoading(false)
      })
  }

  const handleShowModal = idQuiz => {
    setCurrentIdQuiz('')
    setCurrentQuiz({})

    fetch(`https://inova.elitefotodesign.com.br/api/quiz/${idQuiz}`, {
      method: 'GET'
    })
    .then(res => res.json())
    .then(res => {
      setCurrentQuiz(res)
      setCurrentIdQuiz(idQuiz)
      setShowModal(true)
    })
    .catch(e => {
      alert("Um erro ocorreu: " + e)
    })
  }

  let history = useHistory();
  
  if(pageIsLoading) {
    return <Loading loading background="#ffff" loaderColor="#db1a1a" />
  }

  return (
    <>
    <Navbar />
    <div className="container-fluid d-flex flex-wrap">
      {
        quizzes.length == 0 &&
        <h2>Não há questionários cadastrados</h2>
      }
      {
        quizzes.map(el => (
          <div className="card col-12 col-md-3 m-1">
            <div className="card-body">
              <h5 className="card-title">{el.title}</h5>
              <h6 className="card-subtitle mb-2 text-muted">Tipo: {el.type == 'test' ? 'teste' : 'categoria'}</h6>
              <a href="#" onClick={() => handleShowModal(el.id)} className="card-link text-primary">Visualizar</a>
            </div>
          </div>
        ))
      }
    </div>

    <Modal
      size="lg"
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-danger" id="example-custom-modal-styling-title">
          {
            currentQuiz.title != undefined &&
            currentQuiz.title
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          currentQuiz.questions != undefined &&
          currentQuiz.questions.map((el, i) => (
            <>
              <b>{i+1} - {el.desc}</b> <br/>
              {
                el.options.map(opt => (
                  <><span>- {opt.optionTitle}</span><br/></>
                ))
              }
            </>
          ))
        }
      </Modal.Body>
    </Modal>
    </>
  )
}

export default ViewQuests