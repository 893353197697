import { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import Loading from 'react-fullscreen-loading';

import Navbar from '../Navbar/Navbar'

const Classrooms = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true)
  const [classrooms, setClassrooms] = useState([])
  const [showDelModal, setShowDelModal] = useState(false)
  const [currentIdClassroom, setCurrentIdClassroom] = useState('')
  const [newClassroom, setNewClassroom] = useState({
    classroom: '',
    order: ''
  })
  const [errorInvalidFields, setErrorInvalidFields] = useState()

  useEffect(() => {
    getAllClassrooms()
  }, [])

  const getAllClassrooms = () => {
    fetch('https://inova.elitefotodesign.com.br/api/classrooms', { method: 'GET' })
    .then(res => res.json())
    .then(res => {
      setClassrooms(res)
      setPageIsLoading(false)
    })
  }

  const addNewClassroom = () => {
    if(newClassroom.classroom == '' || newClassroom.order == '') {
      setErrorInvalidFields(true)
      return false;
    }

    fetch('https://inova.elitefotodesign.com.br/api/classrooms', { 
      method: 'POST',
      body: JSON.stringify({
        classroom: newClassroom.classroom,
        order: newClassroom.order,
        token: localStorage.getItem('token')
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res.status && res.status == 'error') {
        alert(res.msg)
      } else {
        setClassrooms([...classrooms, res])
        setNewClassroom({
          classroom: '',
          order: ''
        })
        setErrorInvalidFields(false)
      }
    })
  }

  const deleteClassroom = () => {
    fetch(`https://inova.elitefotodesign.com.br/api/classrooms/${currentIdClassroom}`, { 
      method: 'DELETE',
      body: JSON.stringify({ token: localStorage.getItem('token') })
    })
    .then(res => res.json())
    .then(res => {
      if(res.status && res.status == 'error') {
        alert(res.msg)
      } else {
        getAllClassrooms()
      }
    })

    setShowDelModal(false)
  }

  if(pageIsLoading) {
    return <Loading loading background="#ffff" loaderColor="#db1a1a" />
  }

  return (
    <>
    <Navbar/>
    <div className="container">
      <h1 className="my-5 text-left">Gerar séries</h1>
        {
          errorInvalidFields &&
          <p className="text-danger text-left">* Preencha todos os campos</p>
        }
      <div className="d-flex flex-wrap mb-5">
        <input onChange={e => setNewClassroom({...newClassroom, classroom: e.target.value})} value={newClassroom.classroom} type="text" className="form-control col-12 col-md-3 mb-2 mr-2" placeholder="Série..." />
        <input onChange={e => setNewClassroom({...newClassroom, order: e.target.value})} value={newClassroom.order} type="number" className="form-control col-12 col-md-3 mb-2 mr-2" placeholder="Ordem de listagem..." />
        <button onClick={addNewClassroom} className="btn btn-success col-md-3 col-12 mb-2">Adicionar série</button>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Série</th>
            <th scope="col">Ordem de listagem</th>
            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          {
            classrooms.map((el, index) => (
              <tr>
                <td>{el.classroom}</td>
                <td>{el.order}</td>
                <td>
                  <button onClick={() => {
                    setCurrentIdClassroom(el.id)
                    setShowDelModal(true)
                  }} className="btn btn-danger" data-toggle="modal" data-target="#delKeyModal">excluir</button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>

      <Modal show={showDelModal} onHide={() => setShowDelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Excluir série</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Tem certeza que deseja excluir essa série?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => setShowDelModal(false)} variant="secondary">Cancelar</Button>
          <Button onClick={deleteClassroom} variant="danger">Excluir</Button>
        </Modal.Footer>
      </Modal>
    </div>
    </>
  )
}

export default Classrooms