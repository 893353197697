import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import Loading from 'react-fullscreen-loading';

import Navbar from '../Navbar/Navbar'

const AllQuests = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true)
  const [quizzes, setQuizzes] = useState([])
  const [showDelModal, setShowDelModal] = useState(false)
  const [currentQuiz, setCurrentQuiz] = useState('')

  useEffect(() => {
    getAllQuizzes()
  }, [])

  const getAllQuizzes = () => {
    fetch('https://inova.elitefotodesign.com.br/api/quiz', {
      method: 'GET'
    })
      .then(res => res.json())
      .then(res => {
        setQuizzes(res)
        setPageIsLoading(false)
      })
  }

  const deleteQuiz = () => {
    fetch(`https://inova.elitefotodesign.com.br/api/quiz/${currentQuiz}`, { 
      method: 'DELETE',
      body: JSON.stringify({ token: localStorage.getItem('token' )})
    })
    .then(res => res.json())
    .then(res => {
      if(res.status && res.status == 'error') {
        alert(res.msg)
      } else {
        getAllQuizzes()
        setCurrentQuiz('')
      }
    })

    setShowDelModal(false)
  }

  let history = useHistory();
  
  if(pageIsLoading) {
    return <Loading loading background="#ffff" loaderColor="#db1a1a" />
  }

  return (
    <>
    <Navbar />
    <div className="container-fluid d-flex flex-wrap">
      {
        quizzes.length == 0 &&
        <h2>Não há questionários cadastrados</h2>
      }
      {
        quizzes.map(el => (
          <div className="card col-12 col-md-3 m-1">
            <div className="card-body">
              <h5 className="card-title">{el.title}</h5>
              <h6 className="card-subtitle mb-2 text-muted">Tipo: {el.type == 'test' ? 'teste' : 'categoria'}</h6>
              <a href="#" onClick={() => {
                if(el.type == 'category') {
                  history.push(`/admin/quiz-categoria/${el.id}`)
                } else if(el.type == 'test') {
                  history.push(`/admin/quiz-teste/${el.id}`)
                }
              }} className="card-link text-primary">Editar</a>
              <a href="#" onClick={() => {
                setCurrentQuiz(el.id)
                setShowDelModal(true)
              }} className="card-link text-danger" data-toggle="modal" data-target="#exampleModal">Excluir</a>
            </div>
          </div>
        ))
      }

      <Modal show={showDelModal} onHide={() => setShowDelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Excluir quiz</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Tem certeza que deseja excluir esse quiz?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => setShowDelModal(false)} variant="secondary">Cancelar</Button>
          <Button onClick={deleteQuiz} variant="danger">Excluir</Button>
        </Modal.Footer>
      </Modal>

    </div>
    </>
  )
}

export default AllQuests