import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import './App.css';

import CategQuest from './pages/CategQuest/CategQuest'
import TestQuest from './pages/TestQuest/TestQuest'
import AllQuests from './pages/AllQuests/AllQuests'
import ViewQuests from './pages/ViewQuests/ViewQuests'
import Schools from './pages/Schools/Schools'
import KeysGenerator from './pages/KeysGenerator/KeysGenerator'
import Categories from './pages/Categories/Categories'
import Reports from './pages/Reports/Reports'
import Classrooms from './pages/Classrooms/Classrooms'
import WelcomeQuest from './pages/WelcomeQuest/WelcomeQuest'
import Login from './pages/Login/Login'

function App() {
  function PrivateRoute({ children, ...rest }) {
    const token = localStorage.getItem('token')
    if(!token)
      return <Redirect to="/login"/>

    return <Route {...rest} render={() => children} />
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          <PrivateRoute exact path="/admin">
            <Reports />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/quiz-categoria">
            <CategQuest />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/quiz-categoria/:id">
            <CategQuest />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/quiz-teste">
            <TestQuest />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/quiz-teste/:id">
            <TestQuest />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/quiz-todos">
            <AllQuests />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/quiz-visualizar">
            <ViewQuests />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/colegios">
            <Schools />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/series">
            <Classrooms />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/categorias">
            <Categories />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/chaves">
            <KeysGenerator />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/relatorios">
            <Reports />
          </PrivateRoute>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/">
            <WelcomeQuest />
          </Route>
          <Route exact path="/questionario">
            <WelcomeQuest />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
