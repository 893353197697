import { useState, useEffect } from 'react'
import './Quest.css'
import Loading from 'react-fullscreen-loading';

import ResultQuest from '../ResultQuest/ResultQuest'

const Quest = ({ quizId, userId }) => {
  const [pageIsLoading, setPageIsLoading] = useState(true)
  const [quizInfo, setQuizInfo] = useState({})
  const [questions, setQuestions] = useState([])
  const [userQuests, setUserQuests] = useState([])
  const [invalidFields, setInvalidFields] = useState(false)
  const [isSubmited, setIsSubmited] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    getUserQuests()
  }, [])

  const getUserQuests = () => {
    fetch(`https://inova.elitefotodesign.com.br/api/quiz/${quizId}`, { method: 'GET' })
    .then(res => res.json())
    .then(res => {
      setQuizInfo({
        title: res.title,
        type: res.type
      })

      setQuestions(res.questions)
      let arr = []
      res.questions.forEach((q, index) => {
        arr.push({
          questionId: q.id,
          optionId: ''
        })
      })
      setUserQuests(arr)
      setPageIsLoading(false)
    })
    .catch(err => {
      // history.push('/questionario')
    })
  }

  const selectOption = (e, qId) => {
    let arr = [...userQuests]
    arr.forEach(el => {
      if(el.questionId == qId) {
        el.optionId = e.target.value
      }
    })
    setUserQuests(arr)
  }

  const submit = () => {
    let invalid
    userQuests.forEach(async el => {
      if(el.optionId == '') {
        invalid = true
      }
    })

    if(!invalid) {
      setInvalidFields(false)
      fetch('https://inova.elitefotodesign.com.br/api/results', { 
        method: 'POST',
        body: JSON.stringify({
          id_quiz: quizId,
          id_user: userId,
          questions: userQuests
        })
      })
      .then(res => res.json())
      .then(res => {
        setIsSubmited(true)
      })
    } else {
      setInvalidFields(true)
    }

  }

  if(pageIsLoading) {
    return <Loading loading background="#ffff" loaderColor="#db1a1a" />
  }

  if(isSubmited) {
    return <ResultQuest quizId={quizId} userId={userId} quizInfo={quizInfo} questions={questions} userQuests={userQuests} />
  }
  
  return (
    <div className="container text-left">
      {/* {JSON.stringify(userQuests)}
      {JSON.stringify(invalidFields)} */}
      <h2 className="text-danger mt-5">{quizInfo.title}</h2>
      {/* <h6 className="mt-3 mb-5">Bem vindo, {userInfo.name}</h6> */}
      {
        invalidFields &&
        <div className="alert alert-danger" role="alert">
          Parece que você esqueceu de preencher alguma questão :(
        </div>
      }
      {
        questions.map((elQ, indexQ) => (
          <div className="text-left mt-5">
            <h5>{elQ.desc}</h5>
            <div onChange={e => selectOption(e, elQ.id)}>
              {
                elQ.options.map((elOpt, indexOpt) => (
                  <div key={indexOpt} className="form-check">
                    <input className="form-check-input" name={`q${elQ.id}`} type="radio" id={`q${elQ.id}o${elOpt.optionId}`} value={elOpt.optionId}/>
                    <label className="form-check-label" htmlFor={`q${elQ.id}o${elOpt.optionId}`}>
                      {elOpt.optionTitle}
                    </label>
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }

      <button onClick={submit} className="btn btn-success my-5">Responder</button>
    </div>
  )

}

export default Quest