import { useState, useEffect } from "react"
import { Modal, Button, Form } from 'react-bootstrap'
import Loading from 'react-fullscreen-loading';

import Navbar from '../Navbar/Navbar'

const Schools = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true)
  const [schools, setSchools] = useState([])
  const [newSchool, setNewSchool] = useState('')
  const [newName, setNewName] = useState('')
  const [currentIdSchool, setCurrentIdSchool] = useState('')
  const [showDelModal, setShowDelModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)

  const[invalidName, setInvalidName] = useState(false)

  useEffect(() => {
    getAllSchools()
  },[])

  const getAllSchools = () => {
    fetch('https://inova.elitefotodesign.com.br/api/schools', { method: 'GET' })
    .then(res => res.json())
    .then(res => {
      setPageIsLoading(false)
      setSchools(res)
    })
  }

  const addNewSchool = () => {
    if(newSchool == '') {
      setInvalidName(true)
      return false;
    }

    setPageIsLoading(true)
    fetch('https://inova.elitefotodesign.com.br/api/schools', { 
      method: 'POST',
      body: JSON.stringify({name: newSchool, token: localStorage.getItem('token')})
    })
    .then(res => res.json())
    .then(res => {
      if(res.status && res.status == 'error') {
        alert(res.msg)
      } else {
        setSchools([...schools, res])
        setNewSchool('')
        setInvalidName(false)
        setPageIsLoading(false)
      }
    })
  }

  const deleteSchool = () => {
    fetch(`https://inova.elitefotodesign.com.br/api/schools/${currentIdSchool}`, { 
      method: 'DELETE',
      body: JSON.stringify({ token: localStorage.getItem('token') })
    })
    .then(res => res.json())
    .then(res => {
      if(res.status && res.status == 'error') {
        alert(res.msg)
      } else {
        getAllSchools()
      }
    })

    setShowDelModal(false)
  }

  const updateSchool = () => {
    if(!newName) return false

    fetch(`https://inova.elitefotodesign.com.br/api/schools/${currentIdSchool}`, { 
      method: 'PUT',
      body: JSON.stringify({
        name: newName,
        token: localStorage.getItem('token')
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res.status && res.status == 'error') {
        alert(res.msg)
      } else {
        getAllSchools()
      }
    })

    setNewName('')
    setShowEditModal(false)
  }

  if(pageIsLoading) {
    return <Loading loading background="#ffff" loaderColor="#db1a1a" />
  }

  return (
    <>
    <Navbar/>
    <div className="container text-left">
      <h1 className="mb-5">Gerar escolas</h1>
      {
        invalidName &&
        <label className="text-danger">* Nome inválido</label>
      }
      <div className="d-flex">
        <input maxlength="50" value={newSchool} onChange={e => setNewSchool(e.target.value)} type="text" class="form-control col-12 col-md-5 mb-2 mr-2" placeholder="Nome da escola..."/>
        <button onClick={addNewSchool} className="btn btn-success mb-5">Adicionar nova escola</button>
      </div>
      
      {
        schools.length == 0 &&
        <h3>Nenhuma escola cadastrada</h3>
      }
      <div className="d-flex flex-wrap">
        {
          schools.map(el => (
            <div key={el.id} className="card col-12 col-md-3 mr-1 mb-1">
              <div className="card-body">
                <h5 className="card-title">{el.name}</h5>
                <a onClick={() => {
                  setCurrentIdSchool(el.id)
                  setNewName(el.name)
                  setShowEditModal(true)
                }} href="#" className="card-link text-primary" data-toggle="modal" data-target="#editSchoolModal">Editar</a>
                <a onClick={() => {
                  setCurrentIdSchool(el.id)
                  setShowDelModal(true)
                }} href="#" className="card-link text-danger" data-toggle="modal" data-target="#delSchoolModal">Excluir</a>
              </div>
            </div>
          ))
        }
      </div>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar escola</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {
            newName == '' &&
            <p className="text-danger">* Preencha o campo abaixo</p>
          }
          <Form.Control maxlength="50" value={newName} onChange={e => setNewName(e.target.value)} type="text" placeholder="Novo nome..." />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => setShowEditModal(false)} variant="secondary">Cancelar</Button>
          <Button onClick={updateSchool} variant="primary">Alterar</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDelModal} onHide={() => setShowDelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Excluir escola</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Tem certeza que deseja excluir essa escola?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => setShowDelModal(false)} variant="secondary">Cancelar</Button>
          <Button onClick={deleteSchool} variant="danger">Excluir</Button>
        </Modal.Footer>
      </Modal>

    </div>
    </>
  )
}

export default Schools
