import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import Loading from 'react-fullscreen-loading';

const Navbar = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true)
  const [isMasterAdmin, setIsMasterAdmin] = useState(false)

  let history = useHistory();

  useEffect(() => {
    fetch('https://inova.elitefotodesign.com.br/api/login', {
      method: 'POST',
      body: JSON.stringify({
        action: 'check',
        token: localStorage.getItem('token')
      })
    })
    .then(res => res.json())
    .then(res => {
      setPageIsLoading(false)
      if(res.status == 'failed') {
        localStorage.removeItem('token')
        history.push('/login')
      } else {
        if(res.adminType == 'master')
          setIsMasterAdmin(true)
      }
    })
  }, [])

  const logout = () => {
    const token = localStorage.getItem('token')
    fetch('https://inova.elitefotodesign.com.br/api/login', {
      method: 'POST',
      body: JSON.stringify({
        action: 'logout',
        token: token
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res.status == 'success') {
        localStorage.removeItem('token')
        history.push('/login')
      }
    })
  }

  if(pageIsLoading) {
    return <Loading loading background="#ffff" loaderColor="#db1a1a" />
  }

  return (
    <div className="mb-5">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a onClick={() => history.push("/admin/quiz-todos")} className="navbar-brand text-danger" href="#">Quiz Inova Educação</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {
              isMasterAdmin &&
              <>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Quiz
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a onClick={() => history.push("/admin/quiz-categoria")} className="dropdown-item" href="#">Categoria</a>
                    <a onClick={() => history.push("/admin/quiz-teste")} className="dropdown-item" href="#">Teste</a>
                    <a onClick={() => history.push("/admin/quiz-todos")} className="dropdown-item" href="#">Criados</a>
                  </div>
                </li>
                <li className="nav-item">
                  <a onClick={() => history.push("/admin/categorias")} className="nav-link" href="#">Categorias</a>
                </li>
              </>
            }
            {
              !isMasterAdmin &&
              <li className="nav-item">
                <a onClick={() => history.push("/admin/quiz-visualizar")} className="nav-link" href="#">Quiz</a>
              </li>
            }
            <li className="nav-item">
              <a onClick={() => history.push("/admin/colegios")} className="nav-link" href="#">Colégios</a>
            </li>
            <li className="nav-item">
              <a onClick={() => history.push("/admin/series")} className="nav-link" href="#">Séries</a>
            </li>
            <li className="nav-item">
              <a onClick={() => history.push("/admin/chaves")} className="nav-link" href="#">Chaves de acesso</a>
            </li>
            <li className="nav-item">
              <a onClick={() => history.push("/admin/relatorios")} className="nav-link" href="#">Relatórios</a>
            </li>
            <li className="nav-item">
              <a onClick={logout} className="nav-link" href="#">Sair</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default Navbar