import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import './Login.css'

const Login = () => {
  const [invalidFields, setInvalidFields] = useState(false)
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')

  let history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token')
    if(token)
      history.push('/admin')
  }, [])

  const signIn = () => {
    if(!user || !password)
      setInvalidFields(true)

    fetch('https://inova.elitefotodesign.com.br/api/login', {
      method: 'POST',
      body: JSON.stringify({
        action: 'signin',
        user: user,
        password: password 
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res.status == 'success') {
        setInvalidFields(false)
        localStorage.setItem('token', res.token)
        history.push("/admin");
      } else {
        setInvalidFields(true)
      }
    })
  }

  return (
    <div id="login-area" className="text-left d-flex justify-content-center align-items-center">
      <div>
        <input type="text" onChange={e => setUser(e.target.value)} value={user} className="form-control mb-2" placeholder="Usuário..."/>
        <input type="password" onChange={e => setPassword(e.target.value)} value={password} className="form-control mb-2" placeholder="Senha..."/>
        {
          invalidFields &&
          <p className="text-danger">* Usuário ou senha incorretos</p>
        }
        <button onClick={signIn} className="btn btn-danger">Entrar</button>
      </div>
    </div>
  )
}

export default Login