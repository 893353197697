
import { useState, useEffect } from 'react'
import Navbar from '../Navbar/Navbar'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const ClassroomReport = ({ dataUsers, categories }) => {

  if(dataUsers.length == 0) {
    return <h3 className="mt-5">Nenhuma resposta encontrada</h3>
  }

  return (
    <>
      <ReactHTMLTableToExcel
          id="users-table-xls-button"
          className="btn btn-outline-success btn-sm mt-5 mb-1"
          table="table-users"
          filename="relatorio"
          sheet="relatorio"
          buttonText="baixar planilha"/>

      <table className="table" id="table-users">
        <thead>
          <tr>
            <th scope="col">Nome aluno</th>
            <th scope="col">Email aluno</th>
            {
              categories.map(el => <th className="text-primary" scope="col">{el}</th>)
            }
          </tr>
        </thead>
        <tbody>
          {
            dataUsers.map(el => (
              <>
                <tr>
                  <td scope="row">
                    {el.name}
                  </td>
                  <td>
                    {el.email}
                  </td>
                  {
                    el.categs.map(c => <td>{c.porcentage}%</td>)
                  }
                </tr>
              </>
            ))
          }
        </tbody>
      </table>
    </>
  )
}

const SchoolReport = ({ dataSchool }) => {
  return (
    <>
      <ReactHTMLTableToExcel
          id="school-table-xls-button"
          className="btn btn-outline-success btn-sm mt-5 mb-1"
          table="table-school"
          filename="relatorio"
          sheet="relatorio"
          buttonText="baixar planilha"/>
     
      <table className="table" id="table-school">
        <thead>
          <tr>
            {
              dataSchool.map(el => <th className="text-primary" scope="col">{el.categ}</th>)
            }
          </tr>
        </thead>
        <tbody>
          <tr>
          {
            dataSchool.map(el => <td scope="row">{el.porcentage}%</td>)
          }
          </tr>
        </tbody>
      </table>
    </>
  )
}

const Reports = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [classroomReportIsVisible, setClassroomReportIsVisible] = useState(false)
  const [schoolReportIsVisible, setSchoolReportIsVisible] = useState(false)

  const [schools, setSchools] = useState([])
  const [quizzes, setQuizzes] = useState([])
  const [classrooms, setClassrooms] = useState([])

  const [selectedQuiz, setSelectedQuiz] = useState('')
  const [selectedSchool, setSelectedSchool] = useState('')
  const [selectedClassroom, setSelectedClassroom] = useState('')
  const [selectedSerie, setSelectedSerie] = useState('')

  const [dataUsers, setDataUsers] = useState([])
  const [dataSchool, setDataSchool] = useState([])
  const [categories, setCategories] = useState([])

  useEffect(() => {
    fetch('https://inova.elitefotodesign.com.br/api/schools', { method: 'GET' })
    .then(res => res.json())
    .then(res => {
      if(res) {
        setSchools(res)
      } else {
        alert('Erro ao carregar escolas')
      }
    })

    fetch('https://inova.elitefotodesign.com.br/api/quiz', { method: 'GET' })
    .then(res => res.json())
    .then(res => {
      if(res) {
        const quizs = res.filter(el => el.type == 'category')
        setQuizzes(quizs)
      } else {
        alert('Erro ao carregar quiz')
      }
    })

    fetch('https://inova.elitefotodesign.com.br/api/classrooms', { method: 'GET' })
    .then(res => res.json())
    .then(res => {
      if(res) {
        setClassrooms(res)
      } else {
        alert('Erro ao carregar séries')
      }
    })
  }, [])

  const generateReport = () => {
    setClassroomReportIsVisible(false)
    setSchoolReportIsVisible(false)
    
    if(!selectedQuiz || !selectedSchool || 
      (selectedClassroom && !selectedSerie) || 
      (!selectedClassroom && selectedSerie)) 
      return alert('Campos inválidos')
      
    setIsLoading(true)
    if(selectedClassroom && selectedSerie) {
      fetch('https://inova.elitefotodesign.com.br/api/reports', { 
        method: 'POST',
        body: JSON.stringify({
          action: "usersPorcents",
          quiz: selectedQuiz,
          school: selectedSchool,
          classroom: selectedClassroom,
          serie: selectedSerie,
          token: localStorage.getItem('token')
        })
      })
      .then(res => res.json())
      .then(res => {
        if(res) {
          let categs = []
          if(Array.isArray(res.result) && res.result.length > 0 &&  res.result[0].categs)
            categs = res.result[0].categs.map(el => el.desc_category)
          else if(res.result.categs)
            categs = res.result.categs.map(el => el.desc_category)
  
          setCategories(categs)
  
          setDataUsers(res.result)
          setClassroomReportIsVisible(true)
          setIsLoading(false)
        } else {
          setIsLoading(false)
          alert('Erro ao carregar relatório')
        }
      })
    } else {
      fetch('https://inova.elitefotodesign.com.br/api/reports', { 
        method: 'POST',
        body: JSON.stringify({
          action: "schoolPorcents",
          quiz: selectedQuiz,
          school: selectedSchool,
          token: localStorage.getItem('token')
        })
      })
      .then(res => res.json())
      .then(res => {
        setDataSchool(res.result)
        setSchoolReportIsVisible(true)
        setIsLoading(false)
      })
    }

  }

  return (
    <>
      <Navbar/>
      <div className="container text-left">
        <h1>Gerar relatórios de estatísticas</h1>

        <div className="d-flex flex-wrap mt-5">
          <select onChange={e => setSelectedSchool(e.target.value)} value={selectedSchool} className="form-control mr-2 col-12 col-md-5 mb-1">
            <option value="">--Escola--</option>
            {
              schools.map(el => (
                <option value={el.id}>{el.name}</option>
              ))
            }
          </select>

          <select onChange={e => setSelectedQuiz(e.target.value)} value={selectedQuiz} className="form-control mr-2 col-12 col-md-5 mb-1">
            <option value="">--Questionário--</option>
            {
              quizzes.map(el => (
                <option value={el.id}>{el.title}</option>
              ))
            }
          </select>

          <select onChange={e => setSelectedClassroom(e.target.value)} value={selectedClassroom} className="form-control mr-2 col-12 col-md-5 mb-1">
            <option value="">--Série--</option>
            {
              classrooms.map(el => (
                <option value={el.id}>{el.classroom}</option>
              ))
            }
          </select>

          <select onChange={e => setSelectedSerie(e.target.value)} value={selectedSerie} className="form-control mr-2 col-12 col-md-5 mb-1">
            <option value="">--Turma--</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
            <option value="E">E</option>
            <option value="F">F</option>
            <option value="SEM TURMA">SEM TURMA</option>
          </select>

          <button onClick={generateReport} className="btn btn-success mb-1">Gerar relatório</button>
        </div>

        {
          isLoading &&
          <h5 className="mt-5">Carregando...</h5>
        }

        {
          schoolReportIsVisible &&
          <SchoolReport dataSchool={dataSchool} />
        }

        {
          classroomReportIsVisible &&
          <ClassroomReport dataUsers={dataUsers} categories={categories} />
        }

      </div>
    </>
  )
}

export default Reports