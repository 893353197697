import { useState, useEffect } from 'react'
import './KeyControl.css'

const KeyControl = ({ setInfoKey, setAuthorized }) => {
  const [key, setKey] = useState('')
  const [invalidKey, setInvalidKey] = useState(false)

  const checkKey = () => {
    if(!key) {
      setInvalidKey(true)
      return false
    }

    fetch(`https://inova.elitefotodesign.com.br/api/checkkey/${key}`, { method: 'GET'})
    .then(res => res.json())
    .then(res => {
      if(!res) {
        setInvalidKey(true)
      } else {
        setInfoKey(res)
        setAuthorized(true)
        setInvalidKey(false)
      }
    })
  }

  return (
    <div id="keyControlArea">
      {
        invalidKey &&
        <span>* Chave inválida</span>
      }
      <input value={key} onChange={e => setKey(e.target.value)} type="text" placeholder="Chave de acesso..."/>
      <button onClick={checkKey}>Acessar</button>
    </div>
  )
}

export default KeyControl