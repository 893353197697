import { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import Navbar from '../Navbar/Navbar'
import Loading from 'react-fullscreen-loading';

const KeysGenerator = () => {
  const [pageIsLoading, setPageIsLoading] = useState(true)
  const [saveError, setSaveError] = useState(false)
  const [schools, setSchools] = useState([])
  const [quizzes, setQuizzes] = useState([])
  const [newKey, setNewKey] = useState({
    schoolId: '',
    quizId: '',
    key: ''
  })
  const [allKeys, setAllKeys] = useState([])
  const [currentKey, setCurrentKey] = useState('')
  const [showDelModal, setShowDelModal] = useState(false)

  useEffect(() => {
    getAllKeys()
    
    fetch('https://inova.elitefotodesign.com.br/api/quiz', {
      method: 'GET'
    })
    .then(res => res.json())
    .then(setQuizzes)

    fetch('https://inova.elitefotodesign.com.br/api/schools', {
      method: 'GET'
    })
    .then(res => res.json())
    .then(setSchools)
  }, [])

  const getAllKeys = () => {
    fetch('https://inova.elitefotodesign.com.br/api/keys', {
      method: 'GET'
    })
    .then(res => res.json())
    .then(res => {
      setAllKeys(res)
      setPageIsLoading(false)
    })
  }

  const addNewKey = () => {
    if(!newKey.schoolId | !newKey.quizId | !newKey.key) {
      setSaveError(true)
    } else {
      setSaveError(false)
      fetch('https://inova.elitefotodesign.com.br/api/keys', {
        method: 'POST',
        body: JSON.stringify({...newKey, token: localStorage.getItem('token')})
      })
      .then(res => res.json())
      .then(res => {
        if(res.status && res.status == 'error') {
          alert(res.msg)
        } else {
          getAllKeys()
          setNewKey({
            schoolId: '',
            quizId: '',
            key: ''
          })
        }
      })
      
    }
  }

  const deleteKey = () => {
    fetch(`https://inova.elitefotodesign.com.br/api/keys/${currentKey}`, { 
      method: 'DELETE',
      body: JSON.stringify({ token: localStorage.getItem('token') })
    })
    .then(res => res.json())
    .then(res => {
      if(res.status && res.status == 'error') {
        alert(res.msg)
      } else {
        getAllKeys()
      }
    })

    setShowDelModal(false)
  }

  if(pageIsLoading) {
    return <Loading loading background="#ffff" loaderColor="#db1a1a" />
  }

  return (
    <>
    <Navbar/>
    <div className="container">
      <h1 className="my-5 text-left">Gerar chaves de acesso</h1>
      {
        saveError &&
        <p className="text-danger text-left">* Há campos a serem preenchidos</p>
      }
      <div className="d-flex flex-wrap mb-5">
        <select value={newKey.schoolId} onChange={e => setNewKey({...newKey, schoolId: e.target.value})} className="form-control mr-2 col-12 col-md-3 mb-2">
          <option value="">--Colégio--</option>
          {
            schools.map(el => (
              <option value={el.id}>{el.name}</option>
            ))
          }
        </select>
        <select value={newKey.quizId} onChange={e => setNewKey({...newKey, quizId: e.target.value})} className="form-control mr-2 col-12 col-md-3 mb-2">
          <option value="">--Questionário--</option>
          {
            quizzes.map(el => (
              <option value={el.id}>{el.title}</option>
            ))
          }
        </select>
        <input value={newKey.key} onChange={e => setNewKey({...newKey, key: e.target.value})} type="text" className="form-control col-12 col-md-3 mb-2 mr-2" placeholder="Chave personalizada..." />
        <button onClick={addNewKey} className="btn btn-success col-md-2 col-12 mb-2">Adicionar chave</button>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Colégio</th>
            <th scope="col">Questionário</th>
            <th scope="col">Chave</th>
            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          {
            allKeys.map(el => (
              <tr>
                <td>{el.school}</td>
                <td>{el.quiz}</td>
                <td>{el.key}</td>
                <td>
                  <button onClick={ () => {
                    setCurrentKey(el.id)
                    setShowDelModal(true)
                  }} className="btn btn-danger" data-toggle="modal" data-target="#delKeyModal">excluir</button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>

      <Modal show={showDelModal} onHide={() => setShowDelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Excluir chave</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Tem certeza que deseja excluir essa chave?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => setShowDelModal(false)} variant="secondary">Cancelar</Button>
          <Button onClick={deleteKey} variant="danger">Excluir</Button>
        </Modal.Footer>
      </Modal>

    </div>
    </>
  )
}

export default KeysGenerator