import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";

import Navbar from '../Navbar/Navbar'

const TestQuest = () => {

  const [title, setTitle] = useState('')
  const [questions, setQuestions] = useState([
    { 
      key: 0,
      id: '',
      desc: '',
      options: [
        {
          optionKey: 0,
          optionId: '',
          optionTitle: '',
          optionCorrect: true
        },
        {
          optionKey: 1,
          optionId: '',
          optionTitle: '',
          optionCorrect: false
        },
        {
          optionKey: 2,
          optionId: '',
          optionTitle: '',
          optionCorrect: false
        },
        {
          optionKey: 3,
          optionId: '',
          optionTitle: '',
          optionCorrect: false
        }
      ]
    }
  ])
  const [saveError, setSaveError] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(false)

  let { id } = useParams();

  useEffect(() => {

    if(id === 0 || id) {
      fetch(`https://inova.elitefotodesign.com.br/api/quiz/${id}`, { method: 'GET' })
      .then(res => res.json())
      .then(res => {
        console.log(res)
        setQuestions(res.questions)
        setTitle(res.title)
      })
    }
  }, [])

  const addQuest = () => {
    const newKey = questions.length > 0 ? questions[questions.length -1].key + 1 : 0
    setQuestions([...questions, { 
        key: newKey,
        id: '',
        desc: '',
        options: [
          {
            optionKey: 0,
            optionId: '',
            optionTitle: '',
            optionCorrect: true
          },
          {
            optionKey: 1,
            optionId: '',
            optionTitle: '',
            optionCorrect: false
          },
          {
            optionKey: 2,
            optionId: '',
            optionTitle: '',
            optionCorrect: false
          },
          {
            optionKey: 3,
            optionId: '',
            optionTitle: '',
            optionCorrect: false
          }
        ]
      }
    ])
  }

  const delQuest = key => {
    const newArray = questions.filter(el => el.key != key)
    setQuestions(newArray)
  }

  const addOption = index => {
    let newKey = 0
    if(questions[index].options.length > 0) {
      const lastOptionIndex = questions[index].options.length - 1
      const lastOption =  questions[index].options[lastOptionIndex]
      newKey = lastOption.optionKey + 1
    }
    
    let newArr = [...questions]
    newArr[index].options.push({
      optionKey: newKey,
      optionTitle: '',
      optionCorrect: false
    })

    setQuestions(newArr)
  }

  const delOption = (questionIndex, optionIndex) => {
    let newArr = [...questions]
    let newOptions = newArr[questionIndex].options.filter((e,index) => index != optionIndex)
    
    if(newArr[questionIndex].options[optionIndex].optionCorrect) {
      newOptions[0].optionCorrect = true
      newOptions.forEach((el, index) => {
        if(index != 0) el.optionCorrect = false
      })
    }
    newArr[questionIndex].options = newOptions
    setQuestions(newArr)
  }

  const updateDesc = (e, index) => {
    let newArr = [...questions]
    newArr[index].desc = e.target.value
    setQuestions(newArr)
  }

  const updateOptionTitle = (e, questionIndex, optionIndex) => {
    let newArr = [...questions]
    newArr[questionIndex].options[optionIndex].optionTitle = e.target.value
    setQuestions(newArr)
  }

  const updateCorrectOptions = (questionIndex, optionIndex) => {
    let newArr = [...questions]
    newArr[questionIndex].options[optionIndex].optionCorrect = true 

    newArr[questionIndex].options.forEach((el,index) => {
      if(el.optionCorrect) 
        el.optionCorrect = false
      
      if(index == optionIndex)
        el.optionCorrect = true
    })
    setQuestions(newArr)
  }

  const save = () => {
    setSaveError(false)
    setSaveSuccess(false)
    let success1 = questions.every(el => el.desc != '')
    let success2 = false
    
    questions.forEach(q => {
      success2 = q.options.every(el => (
        el.optionTitle != ''
      ))
    })

    if(success1 && success2 && title != '') {

      if(id === 0 || id) {
        updateQuiz()
        return false
      }

      fetch('https://inova.elitefotodesign.com.br/api/quiz', { 
          method: 'POST',
          body: JSON.stringify({
            title: title,
            type: 'test',
            questions: questions,
            token: localStorage.getItem('token')
          })
        })
        .then(res => res.json())
        .then(res => {
          if(res.status && res.status == 'error') {
            alert(res.msg)
          } else {
            setTitle('')
            setQuestions([{ 
              key: 0,
              id: '',
              desc: '',
              options: [
                {
                  optionKey: 0,
                  optionId: '',
                  optionTitle: '',
                  optionCorrect: true
                },
                {
                  optionKey: 1,
                  optionId: '',
                  optionTitle: '',
                  optionCorrect: false
                },
                {
                  optionKey: 2,
                  optionId: '',
                  optionTitle: '',
                  optionCorrect: false
                },
                {
                  optionKey: 3,
                  optionId: '',
                  optionTitle: '',
                  optionCorrect: false
                }
              ]
              }
            ])
            setSaveSuccess(true)
          }
        })
    } else {
      setSaveError(true)
    }
  }

  const updateQuiz = () => {
    fetch(`https://inova.elitefotodesign.com.br/api/quiz/${id}`, { 
      method: 'PUT',
      body: JSON.stringify({
        title: title,
        type: 'test',
        questions: questions,
        token: localStorage.getItem('token')
      })
    })
    .then(res => res.json())
    .then(res => {
      if(res.status && res.status == 'error') {
        alert(res.msg)
      } else {
        setSaveSuccess(true)
      }
    })
  }

  return (
    <>
    <Navbar/>
    <main id="handle-quest-page" className="container text-left">
      {/* {JSON.stringify(questions)} */}
      {
        saveSuccess &&
        <div class="alert alert-success" role="alert">
          Questionário salvo com successo!
        </div>
      }
      {
        saveError &&
        <div class="alert alert-danger" role="alert">
          Há campos a serem preenchidos!
        </div>
      }

      {
        (id === 0 || id) &&
        <h3 className="text-danger">Modo edição</h3>
      }
      <h1>Questionário - tipo teste</h1>

      <label className="text-left w-100 mt-5 ">Título do questionário</label>
      <input value={title} onChange={e => setTitle(e.target.value)} id="handle-quest-title" className="form-control" placeholder="" type="text" />
      
      {
        questions.map( (el, index) => (
            <div key={el.key}>
              <h4 className="mt-5 mb-3 alert alert-secondary">Pergunta {index+1}</h4>
              <div className="form-group">
                <label className="text-left w-100">Descrição da pergunta</label>
                <textarea onChange={e => updateDesc(e, index)} value={el.desc} class="form-control" placeholder=""></textarea>
              </div>
              
              <div className="form-group">
                <label className="mt-3 text-left w-100">Adicione as alternativas e marque a correta</label>
                {
                  el.options.map( (elOpt, indexOpt) => (
                    <div key={elOpt.optionKey} className="input-group mb-3">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <input onClick={() => updateCorrectOptions(index, indexOpt)} name={'opt'+index} checked={elOpt.optionCorrect} type="radio" aria-label="Radio button for following text input"/>
                        </div>
                      </div>
                      <input onChange={e => updateOptionTitle(e,index, indexOpt)} value={elOpt.optionTitle} type="text" className="form-control" aria-label="Text input with radio button"/>
                      {
                        indexOpt > 0 &&  
                        <button onClick={() => delOption(index, indexOpt)} className="btn btn-danger">x</button>
                      }
                    </div>
                  ))
                }
                <button onClick={() => addOption(index)} className="btn btn-secondary mt-3">Adicionar alternativa</button> <br/>
                {
                  index > 0 &&
                  <button className="btn btn-danger mt-3 mb-2" onClick={() => delQuest(el.key)}>Deletar pergunta</button>
                }
              </div>
            </div>
          )
        )
      }

      <div className="my-5 ">
        <button className="btn btn-primary mr-2" onClick={addQuest}>Adicionar pergunta</button>
        <button className="btn btn-success" onClick={save}>Salvar questionário</button>
      </div>
    </main>
    </>
  )
}

export default TestQuest