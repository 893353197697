import { useState, useEffect } from 'react'

import KeyControl from '../KeyControl/KeyControl'
import Quest from '../Quest/Quest'
import './WelcomeQuest.css'

const WelcomeQuest = () => {
  const [authorized, setAuthorized] = useState(false)
  const [infoKey, setInfoKey] = useState({})
  const [classrooms, setClassrooms] = useState([])
  const [invalidFields, setInvalidFields] = useState(false)
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    classroomId: '',
    classroomSerie: ''
  })
  const [isSubmited, setIsSubmited] = useState(false)
  const [userId, setUserId] = useState('')

  useEffect(() => {
    fetch('https://inova.elitefotodesign.com.br/api/classrooms', { method: 'GET'})
    .then(res => res.json())
    .then(setClassrooms)
  },[])

  const submit = () => {
    if(
      userInfo.name == '' ||
      userInfo.email == '' ||
      userInfo.classroomId == '' ||
      userInfo.classroomSerie == ''
    ) {
      setInvalidFields(true)
    } else {
      setInvalidFields(false)
      fetch('https://inova.elitefotodesign.com.br/api/users', {
        method: 'POST',
        body: JSON.stringify({
          name: userInfo.name,
          email: userInfo.email,
          id_classroom: userInfo.classroomId,
          id_school: infoKey.id_school,
          classroom_serie: userInfo.classroomSerie
        })
      })
      .then(res => res.json())
      .then(res => {
        if(res.status == 'error') {
          alert('Um erro inesperado ocorreu.')
        } else {
          setIsSubmited(true)
          setUserId(res.id_user)
          // history.push(`/questionario/${infoKey.id_quiz}/${res.id_user}`)
        }
      })
    }
  }

  if(isSubmited) {
    return <Quest quizId={infoKey.id_quiz} userId={userId} />
  }

  if(authorized) {
    return (
      <div id="welcomeQuestArea">
        <main>
          <h2 className="text-danger">{infoKey.quiz}</h2>
          <h4 className="mb-4">{infoKey.school}</h4>
          <input value={userInfo.name} onChange={e => setUserInfo({...userInfo, name: e.target.value})} type="text" className="form-control mb-1" placeholder="Nome..." />
          <input value={userInfo.email} onChange={e => setUserInfo({...userInfo, email: e.target.value})} type="email" className="form-control mb-1" placeholder="Email..." />
          <div className="d-flex">
            <select onChange={e => setUserInfo({...userInfo, classroomId: e.target.value})} value={userInfo.classroomId} class="form-control mr-1">
              <option key="-1" value="">--Série--</option>
              {
                classrooms.map(el => (
                  <option key={el.id} value={el.id}>{el.classroom}</option>
                ))
              }
            </select>
            <select onChange={e => setUserInfo({...userInfo, classroomSerie: e.target.value})} value={userInfo.classroomSerie} class="form-control">
              <option value="">--Turma--</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
              <option value="E">E</option>
              <option value="F">F</option>
              <option value="SEM TURMA">SEM TURMA</option>
            </select>
          </div>

          {
            invalidFields &&
            <><div className="text-danger text-left mt-2">* Preencha todos os campos</div><br/></>
          }

          <button onClick={submit} className="btn btn-danger mt-4">Acessar questionário</button>
        </main>
      </div>
      )
    } else {
      return (
      <KeyControl setAuthorized={setAuthorized} setInfoKey={setInfoKey}/>
    )
  }
}

export default WelcomeQuest